export const skills = [
    {
        nombre: 'JavaScript',
        porcentaje : '1',
        imagen: 'img/js.png',
        progress: '1'
    },
    {
        nombre: 'TypeScript',
        porcentaje : '0.9',
        imagen: 'img/ts.png',
        progress: '2'
    },
    {
        nombre: 'CSS/Sass',
        porcentaje : '1',
        imagen: 'img/sass.png',
        progress: '3'
    },
    {
        nombre: 'VueJs',
        porcentaje : '0.6',
        imagen: 'img/vjs.png',
        progress: '4'
    },
    {
        nombre: 'Drupal',
        porcentaje : '0.7',
        imagen: 'img/drupal.png',
        progress: '5'
    },
    {
        nombre: 'Wordpress',
        porcentaje : '1',
        imagen: 'img/wordpress.png',
        progress: '6'
    },
]