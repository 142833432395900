import '../../sass/pages/_portafolio.scss'
import { selectLanguage, translateSite } from '../services/languages';
import { skills } from '../utils/skills' 
import { proyects } from '../utils/projects' 
import { database } from '../services/firebase';

import * as AOS from "aos";
import 'aos/dist/aos.css';

import * as Bouncer from "formbouncerjs"

const skillsData = () => {
	let container = document.querySelector('.portafolio-content--skills--content--items') as HTMLElement;
	let delay = 500;
	skills.forEach(function(skill) {

		let percentageComplete = skill.porcentaje as any;
		let porcentaje = 0 + (percentageComplete * 100);
		
		container.innerHTML += `
			<div id="card-${skill.progress}" class="card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="${delay}">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
					<circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />
					<circle cx="16" cy="16" r="15.9155"  class="progress-bar__progress js-progress-bar progress-${skill.progress}" />
				</svg>
				<div class="number_porcentaje">
					<p><span>${porcentaje}</span>%</p>
				</div>
				<div class="logoSkill">
					<img src="${skill.imagen}" alt="${skill.imagen}" alt="${skill.imagen}" />
				</div>
				<p class="nameCard">${skill.nombre}</p>
			</div>
		`;
		delay = delay + 500;
	});
	efectoBarras();
}

const efectoBarras = () => {
	let cards = document.querySelectorAll('.card') as any;

	const progressBar = (entradas:any, observador?:any) => {
		for(let i=0; i < entradas.length; i++ ){
			if(screen.width > 911){
				if(entradas[i].isIntersecting){
					setTimeout(function(){
						let percentageComplete = skills[i].porcentaje as any;
						let strokeDashOffsetValue = 100 - (percentageComplete * 100);
						let progressBar = document.querySelector(`.progress-${skills[i].progress}`) as any;
						progressBar.style.strokeDashoffset = strokeDashOffsetValue;
					}, 4000);
				}
			}else{
				setTimeout(function(){
					let percentageComplete = skills[i].porcentaje as any;
					let strokeDashOffsetValue = 100 - (percentageComplete * 100);
					let progressBar = document.querySelector(`.progress-${skills[i].progress}`) as any;
					progressBar.style.strokeDashoffset = strokeDashOffsetValue;
				}, 4000);
			}
		}
	}

	if(screen.width > 911){
		const observador = new IntersectionObserver(progressBar, {
			root: null,
			rootMargin: '0px',
			threshold: 1.0
		})
		cards.forEach((card:any) => {
			observador.observe(card);
		})
	}else{
		progressBar(cards);
	}
}

const proyectsData = () => {
	let container = document.querySelector('.portafolio-content--proyectos--container') as HTMLElement;
	
	for(let i=0; i < proyects.length; i++) {
		container.innerHTML += `
			<div class="portafolio-content--proyectos--container--items ${i%2 == 0 ? '' : 'itemsRevert'}" data-aos="fade-up" data-aos-duration="1000">
				<img src="${i%2 == 0 ? 'img/LINEAS 1.png' : 'img/LINEAS 2.png'}" alt="lineas" title="lineas" class="lines ${i%2 == 0 ? 'lines--lineProyectos1' : 'lines--lineProyectos2'}" />
				<div class="portafolio-content--proyectos--container--items--pageImage">
					<h4 class="translate" data-language="ESP">${proyects[i].nombreEsp}</h4>
					<h4 class="translate hidden" data-language="ENG">${proyects[i].nombreEng}</h4>
					${
						screen.width > 600 ? 
							`<div class="imgBanner"><img onMouseOver="this.style.transform='translate3d(0px, -${proyects[i].heightBanner - 242}px, 0px)'" onMouseOut="this.style.transform='translate3d(0px, 0px, 0px)'" src="${proyects[i].bannerImg}" alt="${proyects[i].nombreEsp}" alt="${proyects[i].nombreEsp}"/></div>`
							:
							`<div class="imgBanner"><img onMouseOver="this.style.transform='translate3d(0px, -${proyects[i].heightBannerMobile - 242}px, 0px)'" onMouseOut="this.style.transform='translate3d(0px, 0px, 0px)'" src="${proyects[i].bannerImg}" alt="${proyects[i].nombreEsp}" alt="${proyects[i].nombreEsp}"/></div>`
					}
				</div>
				<div class="${i%2 == 0 ? 'portafolio-content--proyectos--container--items--pageInfo' : 'portafolio-content--proyectos--container--items--pageInfo2'}">
					<img src="${proyects[i].numeroImg}" alt="${proyects[i].numeroImg}" title="${proyects[i].numeroImg}" />
					<div class="pageInfo-text translate" data-language="ESP">
							<p>${proyects[i].textosEsp[0].texto1}</p>
							<p>${proyects[i].textosEsp[0].texto2}</p>
							<a ${proyects[i].link ? '' : 'style="display:none;"'} href="https://${proyects[i].link}" target="blank_">${proyects[i].link}</a>
					</div>
					<div class="pageInfo-text translate hidden" data-language="ENG">
							<p>${proyects[i].textosEng[0].texto1}</p>
							<p>${proyects[i].textosEng[0].texto2}</p>
							<a ${proyects[i].link ? '' : 'style="display:none;"'} href="https://${proyects[i].link}" target="blank_">${proyects[i].link}</a>
					</div>
				</div>
			</div>
		`;
	}
}

const validateForm = () => {
	let validate = Bouncer('#formulario', {
		customValidations: {
			phone: function (field:any) {
				if(field.name == 'phone'){
					if(!/^([0-9])*$/.test(field.value)){
						return true;
					}
				}
			},
			digitos: function (field:any) {
				if(field.name == 'phone'){
					if(field.value.length < 7 || field.value.length > 10){
						return true;
					}
				}
			},
			mail: function (field:any) {
				if(field.name == 'mail'){
					let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
					if (!emailRegex.test(field.value)) {
						return true;
					} 
				}
			},
		},
		messages: {
			missingValue: {
				default: '* Por favor llena este campo'
			},
			phone: '* Sólo números en este campo',
			digitos: '* Télefono entre 7 y 10 dígitos',
			mail: '* Por favor dígite un correo válido'
		},
		disableSubmit: true,
	});
}

document.addEventListener('bouncerFormValid', function (event) {
	let form = document.querySelector('#formulario') as any;
	let data = new FormData(form);

	database.collection('registros').doc().set({
		nombre: data.get('name'),
		telefono: data.get('phone'),
		correo: data.get('mail'),
		servicio: data.get('service'),
		mensaje: data.get('massage'),
	}).then(() => {
		form.reset();
		let modal = document.querySelector('.modal-regiter') as HTMLElement;
		modal.classList.add('openModal');
		closeModal();
	})

}, false);

const btnWhatsappForm = () => {
	let btn = document.querySelector('.btnWhatsapp') as any;
	btn.addEventListener('click', function(e:any){
		e.preventDefault()
		location.href = 'https://wa.me/573104629238?text=Hola.%20¿puedes%20ayudarme?';
	})
}

const closeModal = () => {
	let modal = document.querySelector('.modal-regiter') as HTMLElement;
	let btn = document.querySelectorAll('.modal-regiter a') as any;

	btn.forEach((btn:any) => {
		btn.addEventListener('click', function(e:any){
			e.preventDefault();
			modal.classList.remove('openModal');
		})
	});
}

(function page() {
	selectLanguage();
	skillsData();
	proyectsData();
	validateForm();
	btnWhatsappForm();
	AOS.init();
	setTimeout(function(){
		localStorage.getItem('language') ? translateSite(localStorage.getItem('language')) : translateSite('ESP');
	}, 300);
})()