export const selectLanguage = () => {
    let btns = document.querySelectorAll('.header--container--redes--idioma') as any;
    btns.forEach((btn:any) => {
        let idiomaActual = btn?.querySelector('p') as any;
        if(localStorage.getItem('language')){
            idiomaActual.innerHTML = localStorage.getItem('language');
        }
        btn?.addEventListener('click', function(){
            idiomaActual.innerHTML == 'ESP' ? idiomaActual.innerHTML = 'ENG' : idiomaActual.innerHTML = 'ESP';
            translateSite(idiomaActual.innerHTML);
            localStorage.setItem('language', idiomaActual.innerHTML);
        }) 
    });
}

export const translateSite = (language:any) => {
    let textos = document.querySelectorAll(`.translate`) as any;
    textos.forEach((texto:any) => {
        texto.getAttribute('data-language') == language ? texto.classList.remove('hidden') : texto.classList.add('hidden');
    })

    let inputs = document.querySelectorAll('.dataForm') as any;
    switch (language) {
        case 'ESP':
            inputs[0].placeholder = "Nombre";
            inputs[1].placeholder = "Celular";
            inputs[2].placeholder = "Correo electrónico";
            inputs[3].placeholder = "Servicio";
            inputs[4].placeholder = "Mensaje";
        break;
        case 'ENG':
            inputs[0].placeholder = "Name";
            inputs[1].placeholder = "CellPhone";
            inputs[2].placeholder = "Mail";
            inputs[3].placeholder = "Service";
            inputs[4].placeholder = "Message";
        break;
    }
}