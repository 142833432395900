import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAlUXbJ_dMNkfncciK-dXHP_LfzWpznsNU",
    authDomain: "jorgegildev-29fa3.firebaseapp.com",
    databaseURL: "https://jorgegildev-29fa3-default-rtdb.firebaseio.com",
    projectId: "jorgegildev-29fa3",
    storageBucket: "jorgegildev-29fa3.appspot.com",
    messagingSenderId: "790406567722",
    appId: "1:790406567722:web:e95345d03d928d08b73053"
};

firebase.initializeApp(firebaseConfig);
export const database = firebase.firestore();