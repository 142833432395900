export const proyects = [
    {
        nombreEsp: 'BBC Cervecería Colombia',
        nombreEng: 'BBC Brewery Colombia',
        bannerImg: 'img/BBC-OP.jpg',
        heightBanner: 1794, //tamaño de renderización en la pagina desk
        heightBannerMobile: 1287, //tamaño de renderización en la pagina mobile
        numeroImg: 'img/01.png',
        textosEsp: [
            {
                texto1: 'Proyecto realizado para la marca de cerveza BBC en Colombia.',
                texto2: 'Página desarrollada trabajando en la empresa 150 Porciento'
            }
        ],
        textosEng: [
            {
                texto1: 'Project carried out for the BBC beer brand in Colombia.',
                texto2: 'Page developed working in the company 150 Porciento'
            }
        ],
        link: 'bbccerveceria.com'
    },
    {
        nombreEsp: 'Cerveza Victoria - Día de Muertos',
        nombreEng: 'Victoria Beer - Day of the Dead',
        bannerImg: 'img/VICTORIA-OP.jpg',
        heightBanner: 600, //tamaño de renderización en la pagina
        heightBannerMobile: 430, //tamaño de renderización en la pagina mobile
        numeroImg: 'img/02.png',
        textosEsp: [
            {
                texto1: 'Proyecto realizado para la marca Mexicana de cerveza Victoria en su celebración nacional del día de muertos. Su campaña de temporada es llamada Victoria Cempasúchil.',
                texto2: 'Página desarrollada trabajando en la empresa 150 Porciento'
            }
        ],
        textosEng: [
            {
                texto1: 'Project made for the Mexican brand of beer Victoria in its national celebration of the day of the dead. His seasonal campaign is called Victoria Cempasúchil.',
                texto2: 'Page developed working in the company 150 Porciento'
            }
        ],
    },
    {
        nombreEsp: 'Jugos HIT - Abejas',
        nombreEng: 'Jugos HIT - bees',
        bannerImg: 'img/HIT-OP.jpg',
        heightBanner: 2080, //tamaño de renderización en la pagina
        heightBannerMobile: 1492, //tamaño de renderización en la pagina mobile
        numeroImg: 'img/03.png',
        textosEsp: [
            {
                texto1: 'Proyecto realizado para la marca Jugos HIT Colombia, para la campaña “La importancia de las abejas”',
                texto2: 'Página desarrollada trabajando en la empresa 150 Porciento'
            }
        ],
        textosEng: [
            {
                texto1: 'Project carried out for the brand Jugos HIT Colombia, for the campaign "The importance of bees"',
                texto2: 'Page developed working in the company 150 Porciento'
            }
        ],
        link: 'somosunhit.com/hitsocial/abejas'
    },
    {
        nombreEsp: 'Stella Artois Ecuador',
        nombreEng: 'Stella Artois Ecuador',
        bannerImg: 'img/STELLA-OP.jpg',
        heightBanner: 1013, //tamaño de renderización en la pagina
        heightBannerMobile: 726, //tamaño de renderización en la pagina mobile
        numeroImg: 'img/04.png',
        textosEsp: [
            {
                texto1: 'Proyecto realizado para la marca Stella Artois Ecuador.',
                texto2: 'Página desarrollada trabajando en la empresa 150 Porciento'
            }
        ],
        textosEng: [
            {
                texto1: 'Project carried out for the brand Stella Artois Ecuador.',
                texto2: 'Page developed working in the company 150 Porciento'
            }
        ],
        link: 'stellaartois.ec'
    },
    {
        nombreEsp: 'Consultorías Eme Marketing',
        nombreEng: 'Eme Marketing Consulting',
        bannerImg: 'img/EME OP.jpg',
        heightBanner: 1819, //tamaño de renderización en la pagina
        heightBannerMobile: 1304, //tamaño de renderización en la pagina mobile
        numeroImg: 'img/05.png',
        textosEsp: [
            {
                texto1: 'Proyecto realizado para la agencia Eme Marketing en Cali',
                texto2: 'Página desarrollada trabajando en la agencia Eme Marketing & Consulting'
            }
        ],
        textosEng: [
            {
                texto1: 'Project carried out for the Eme Marketing agency in Cali',
                texto2: 'Page developed working at the agency Eme Marketing & Consulting'
            }
        ],
        link: 'mediosefectivos.co/consultorias/'
    },
    {
        nombreEsp: 'Landing page Tapetes',
        nombreEng: 'Landing page Rugs',
        bannerImg: 'img/POLAR-OP-2.jpg',
        heightBanner: 516, //tamaño de renderización en la pagina
        heightBannerMobile: 370, //tamaño de renderización en la pagina mobile
        numeroImg: 'img/06.png',
        textosEsp: [
            {
                texto1: 'Proyecto realizado para la empresa Polar Inc. en Cali - Colombia',
                texto2: 'Página desarrollada para promocionar el servicio de venta de tapetes de Polar Inc.'
            }
        ],
        textosEng: [
            {
                texto1: 'Project carried out for the company Polar Inc. in Cali - Colombia',
                texto2: 'Page developed to promote the sales service of Polar Inc. mats.'
            }
        ],
        link: 'polarcolombia.com/tapetes/'
    },
    {
        nombreEsp: 'Landing page polarizados',
        nombreEng: 'Tinted landing page',
        bannerImg: 'img/POLAR-OP.jpg',
        heightBanner: 746, //tamaño de renderización en la pagina
        heightBannerMobile: 535, //tamaño de renderización en la pagina mobile
        numeroImg: 'img/07.png',
        textosEsp: [
            {
                texto1: 'Proyecto realizado para la empresa Polar Inc. en Cali - Colombia',
                texto2: 'Página desarrollada para promocionar el servicio de polarizados de Polar Inc.'
            }
        ],
        textosEng: [
            {
                texto1: 'Project carried out for the company Polar Inc. in Cali - Colombia',
                texto2: 'Page developed to promote the sales service of Polar Inc. mats.'
            }
        ],
        link: 'polarcolombia.com/polarizados/'
    },
]